/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prefer-template */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useLocation, useHistory } from 'react-router-dom';
import Card from '../../Ui/Card';
import { getUserProfile } from '../../redux/features/user/userSlice';
import { getQRById, resetQR } from '../../redux/features/qrCode/qrCodeSlice';
// import { BASE_URL } from '../../utils/axios';
import logo from '../../assets/img/FOX-Logo3.png';

// createQR, setDescription, setEmail, setFile, setName, setPhone, setTitle

function AssetQRCode () {
  const [submited, setSubmited] = useState(false);
  const dispatch = useDispatch();
  const { user, isLoading } = useSelector(state => state.user);
  const {
    service,
    // title,
    // name,
    // email,
    // phone,
    // description,
    // attachment,
    isQRCreateSuccess,
    isQRGetError,
  } = useSelector(state => state.QRService);

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const searchPramId = query.get('uuid');

  const uuid = searchPramId?.slice(0, searchPramId.length - 1);
  useEffect(() => {
    dispatch(getUserProfile());
    dispatch(getQRById(uuid));
  }, [dispatch]);

  // const handelTitle = (e)=>{
  //     dispatch(setTitle(e.target.value));
  // }
  // const handelName = (e)=>{
  //     dispatch(setName(e.target.value));
  // }
  // const handelEmail = (e)=>{
  //     dispatch(setEmail(e.target.value));
  // }
  // const handelPhone = (e)=>{
  //     dispatch(setPhone(e.target.value));
  // }
  // const handelDescription = (e)=>{
  //     dispatch(setDescription(e.target.value));
  // }
  // const handelAttachment = (e)=>{
  //     dispatch(setFile(e.target.files[0]));
  // }

  // const formdata = new FormData();
  // formdata.append('title', title);
  // formdata.append('name', name);
  // formdata.append('email', email);
  // formdata.append('phone', phone);
  // formdata.append('description', description);
  // if (attachment) {
  //     formdata.append('attachment', attachment);
  // }
  // formdata.append('uuid', uuid);

  // const handelSubmit = async (e)=>{
  // e.preventDefault()
  // if (title && name && phone && email && searchPramId ) {
  //    await dispatch(createQR(formdata))
  //    setSubmited(true)
  // }
  // }

  const history = useHistory();

  useEffect(() => {
    if (!isLoading && isQRGetError) {
      if (!searchPramId && user) {
        history.push('/');
      } else if (!searchPramId && !user) {
        history.push('/login');
      }
    }
  }, [searchPramId, user, isLoading, isQRGetError]);

  return (
    <>
      {' '}
      {(!user || service?.activity?.length === 0) && !submited && (
        <>
          <div className="row ml-0 mr-0">
            {/* <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12"> */}
            {/* <div
              className="col col-lg-12 col-md-4 col-sm-4 col-xs-12  logo"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {service?.company_logo ? (
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    width: '150px',
                    height: 'auto',
                    margin: '30px 30 10px 30px',
                    borderRadius: '20%',
                  }}
                />
              ) : (
                <img src={logo} alt="logo" height="130" width="250" />
              )}
            </div> */}
            <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 text-center">
              <h1
                style={{
                  width: 'fit-content',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                {service?.company_name}
              </h1>
            </div>
            {/* </div> */}
          </div>
          <div className="row mt-5 ml-3 mr-3">
            <div
              className="col col-lg-12 col-sm-12 col-md-12 col-xs-12"
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
              }}
            >
              <Card className="col-lg-5 col-sm-12 col-md-12 col-xs-12">
                {/* <div className="card col-lg-12 col-sm-12 col-md-12 col-xs-12"> */}
                <div className="card-header medium-title">
                  <h4 className="text-center">
                    <b>Asset Details</b>
                  </h4>
                </div>
                <div className="card-body pb-3">
                  <div className="row medium-title">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 row mb-4">
                      <label className="col-6">Name:</label>
                      <span className="col-6">{service?.name}</span>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12 row">
                      <label className="col-6">Location:</label>
                      <span className="col-6">
                        {`${service?.location?.site}${
                          service?.sub_location?.room ? `, ` + service?.sub_location?.room : ''
                        }`}
                      </span>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </Card>
              {/* <Card myClassNaclassName="col-lg-6 col-sm-12 col-md-12 col-xs-12">
           <form action="action" method='POST' onSubmit={handelSubmit} >
               <div className="card">
                   <div className="card-header mt-2">
                       <h4><b>Create your work order</b></h4>
                   </div>
                   <div className="card-body pb-0">
                       <input name="created_date" id="created_date" type="hidden" />
                       <label className="font-weight-normal">Title<span className="font-danger">*</span></label>
                       <input type="text"  
                       name="wo_title" id="wo_title" 
                       className="form-control mb-2" 
                       required
                       value={title}
                       onChange={handelTitle}
                        />
                       <label className="font-weight-normal">Name<span className="font-danger">*</span></label>
                       <input type="text" name="name" id="name" className="form-control mb-2" 
                       onChange={handelName}
                       value={name} required/>
                       <span className="font-danger float-left col-lg-12 col-md-12 col-sm-12" id="name_error"></span>
       
                       <div className="row">
                           <div className="col-lg-8 col-sm-12 col-md-7">
                               <label className="font-weight-normal">Email<span className="font-danger">*</span></label>
                               <input type="email" name="email" id="email" onChange={handelEmail} className="form-control mb-2"
                              value={email}  required />
                           </div>
                           <div className="col-lg-4 col-sm-12 col-md-5">
                               <label className="font-weight-normal">Phone<span className="font-danger">*</span></label>
                               <input type="number" name="phone" id="phone"
                               value={phone}
                                onChange={handelPhone} className="form-control mb-2"/>
                           </div>
                       </div>
       
                       <div id="comment">
                           <label className="font-weight-normal">Description<span className="font-danger">*</span></label>
                           <textarea onChange={handelDescription}
                           value={description}
                            name="comment" id="comment_desc" className="form-control mb-2" required></textarea>
                           <span className="font-danger float-left col-lg-12 col-md-12 col-sm-12" id="comment_error"></span>
                       </div>
                       <label className="font-weight-normal">Attachment</label>
                       <input type="file" name="image" id="image" className="form-control mb-2" style={{border: "none"}} 
                       onChange={handelAttachment}/>
                   </div>
                   <center>
                       <button type="submit" id='srvc-btn' className="btn primary-btn mb-5" >Submit</button>
                   </center>
               </div>
       </form>
       </Card> */}
            </div>
          </div>
        </>
      )}
      {!submited && user && service?.activity?.length > 0 && (
        <>
          {' '}
          <div className="row ml-0 mr-0">
            <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="col col-lg-4 col-md-4 col-sm-4 col-xs-12  mb-2 logo float-left">
                {service?.company_logo ? (
                  <img
                    src={service?.company_logo}
                    alt="logo"
                    style={{
                      width: '180px',
                      height: '60px',
                      margin: '30px',
                      borderRadius: '20%',
                    }}
                  />
                ) : (
                  <img src={logo} alt="logo" height="130" width="250" />
                )}
              </div>
              <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 mt-5 text-center">
                <h1
                  style={{
                    width: 'fit-content',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  {service?.company_name}
                </h1>
              </div>
            </div>
          </div>
          <div className="row mt-5 ml-0 mr-0">
            <div className="col-lg-6 col-sm-12 col-md-12 col-xs-12 pl-3 pr-3">
              <section className="content">
                <div className="row">
                  <div className="col-lg-12 col-sm-12">
                    <Card>
                      <div className="card">
                        <div className="card-header">
                          <h4 className="col col-lg-10 col-sm-12 col-md-12 col-xs-12 mt-2">
                            <b>Asset Maintenance History</b>
                          </h4>
                        </div>
                        <div
                          style={{
                            height: '400px',
                          }}
                          className="card-body pb-3 overflow-auto"
                        >
                          <div>
                            <table
                              id="service-history-list"
                              className="table table-bordered table-striped table-togglable w-100"
                            >
                              <thead>
                                <tr>
                                  <th>
                                    <center>ID</center>
                                  </th>
                                  <th>
                                    <center>Date</center>
                                  </th>
                                  <th>
                                    <center>Activity</center>
                                  </th>
                                  <th>
                                    <center>Updater</center>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {service?.activity &&
                                  service?.activity?.map(act => (
                                    <tr key={act?.id}>
                                      <td>
                                        <b>{act.reference}</b>
                                      </td>
                                      <td>
                                        <center>
                                          <span className="d-none"></span>
                                          {moment(act.history_date).format('YYYY-MM-DD')}
                                        </center>
                                      </td>
                                      <td>{act.history_change_reason}</td>
                                      <td>
                                        <center>{act.updated_by}</center>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </section>
            </div>
            <div className="service-page col-lg-6 col-sm-12 col-md-12 col-xs-12">
              <Card>
                <div className="card ml-2 mr-2">
                  <div className="card-header">
                    <div className="row justify-content-between">
                      <h4 className="col col-lg-6 col-md-6 col-xs-12 mt-2">
                        <b>Asset Details</b>
                      </h4>
                      {service?.image && (
                        <img src={service?.image} alt="logo" height="80" width="100" />
                      )}
                    </div>
                  </div>
                  <div className="card-body pb-2">
                    <div className="row medium-title">
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                        <label className="col-sm-6 col-md-6 col-lg-6 col-xs-6">
                          Serial Number:
                        </label>
                        <span className="col-sm-6 col-md-6 col-lg-6 col-xs-6">
                          {' '}
                          {service?.serial_number}
                        </span>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                        <label className="col-sm-6 col-md-6 col-lg-6 col-xs-6">Name:</label>
                        <span className="col-sm-6 col-md-6 col-lg-6 col-xs-6">{service?.name}</span>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                        <label className="col-sm-6 col-md-6 col-lg-6 col-xs-6">Asset Type: </label>
                        <span className="col-sm-6 col-md-6 col-lg-6 col-xs-6">
                          {' '}
                          {service?.asset_type?.name}
                        </span>
                      </div>
                      {service?.description && (
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                          <label className="col-sm-6 col-md-6 col-lg-6 col-xs-6">
                            Description:
                          </label>
                          <span className="col-sm-6 col-md-6 col-lg-6 col-xs-6">
                            {' '}
                            {service?.description}
                          </span>
                        </div>
                      )}
                      {service?.brand && (
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                          <label className="col-sm-6 col-md-6 col-lg-6 col-xs-6">Brand:</label>
                          <span className="col-sm-6 col-md-6 col-lg-6 col-xs-6">
                            {service?.brand}
                          </span>
                        </div>
                      )}
                      <div
                        className="col-sm-12 col-md-12 col-lg-12 col-xs-12"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <label className="col-sm-6 col-md-6 col-lg-6 col-xs-6">Custodian:</label>
                        <span className="col-sm-6 col-md-6 col-lg-6 col-xs-6">
                          {service?.assigned_to?.map(person => (
                            <div
                              className="col-sm-12 col-md-12 col-lg-12 col-xs-12"
                              key={person?.id}
                            >{`${person?.name} (${
                              person?.role === 'CliAdm' ? 'Admin' : person?.role
                            })`}</div>
                          ))}
                        </span>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                        <label className="col-sm-6 col-md-6 col-lg-6 col-xs-6">Category:</label>
                        <span className="col-sm-6 col-md-6 col-lg-6 col-xs-6">
                          {' '}
                          {service?.category?.name}
                        </span>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xs-12">
                        <label className="col-sm-6 col-md-6 col-lg-6 col-xs-6">Location:</label>
                        <span className="col-sm-6 col-md-6 col-lg-6 col-xs-6">
                          {`${service?.location?.site}${
                            service?.sub_location?.room
                              ? `${
                                  service?.sub_location?.room && `, ` + service?.sub_location?.room
                                }${
                                  service?.sub_location?.suite &&
                                  `, ` + service?.sub_location?.suite
                                }${
                                  service?.sub_location?.level &&
                                  `, ` + service?.sub_location?.level
                                }${
                                  service?.sub_location?.building &&
                                  `, ` + service?.sub_location?.building
                                }`
                              : ''
                          }`}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
              {/*         
           <form action="action" method='POST' onSubmit={handelSubmit} >
            <Card>
               <div className="card">
                   <div className="card-header mt-2">
                       <h4><b>Create your work order</b></h4>
                   </div>
                   <div className="card-body pb-2">
                       <input name="created_date" id="created_date" type="hidden" />
                       <label className="font-weight-normal">Title<span className="font-danger">*</span></label>
                       <input type="text"  
                       name="wo_title" id="wo_title" 
                       className="form-control mb-2" 
                       required
                       value={title}
                       onChange={handelTitle}
                        />
                       <label className="font-weight-normal">Name<span className="font-danger">*</span></label>
                       <input type="text" name="name" id="name" className="form-control mb-2" 
                       onChange={handelName}
                       value={name} required/>
                       <span className="font-danger float-left col-lg-12 col-md-12 col-sm-12" id="name_error"></span>
       
                       <div className="row">
                           <div className="col-lg-8 col-sm-12 col-md-7">
                               <label className="font-weight-normal">Email<span className="font-danger">*</span></label>
                               <input type="email" name="email" id="email" onChange={handelEmail} className="form-control mb-2"
                              value={email}  required />
                           </div>
                           <div className="col-lg-4 col-sm-12 col-md-5">
                               <label className="font-weight-normal">Phone<span className="font-danger">*</span></label>
                               <input type="number" name="phone" id="phone"
                               value={phone}
                                onChange={handelPhone} className="form-control mb-2"/>
                           </div>
                       </div>
                       <div id="comment">
                           <label className="font-weight-normal">Description<span className="font-danger">*</span></label>
                           <textarea onChange={handelDescription}
                           value={description}
                            name="comment" id="comment_desc" className="form-control mb-2" required></textarea>
                           <span className="font-danger float-left col-lg-12 col-md-12 col-sm-12" id="comment_error"></span>
                       </div>
                       <label className="font-weight-normal">Attachment</label>
                       <input type="file" name="image" id="image" className="form-control mb-2" style={{border: "none"}} 
                       onChange={handelAttachment}/>
                   </div>
                   <center>
                       <button type="submit" id='srvc-btn' className="btn primary-btn mb-3" > Submit </button>
                   </center>
               </div> 
               </Card>
       </form> */}
            </div>
          </div>
        </>
      )}
      {submited && (
        <>
          {' '}
          <div className="row ml-0 mr-0">
            <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="col col-lg-4 col-md-4 col-sm-4 col-xs-12  mb-2 logo float-left">
                {service?.company_logo ? (
                  <img
                    src={service?.company_logo}
                    alt="logo"
                    style={{
                      width: '180px',
                      height: '60px',
                      margin: '30px',
                      borderRadius: '20%',
                    }}
                  />
                ) : (
                  <img src={logo} alt="logo" height="130" width="250" />
                )}
              </div>
              <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 mt-5 text-center">
                <h1
                  style={{
                    width: 'fit-content',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  {service?.company_name}
                </h1>
              </div>
            </div>
          </div>
          <div className="row mt-5 ml-0 mr-0">
            <div className="col-lg-12 col-sm-12 col-xs-12 m-2">
              <span className="font-danger error_msg float-left" id="serv_err_msg"></span>
            </div>
            <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 pl-3 pr-3">
              <section className="content">
                <div className="row">
                  <div className="col-lg-12 col-sm-12">
                    <div className="card">
                      <Card>
                        <div className="card-header text-center">
                          {!isQRCreateSuccess && <h1>Error!</h1>}
                          {isQRCreateSuccess && <h1>THANK YOU!</h1>}
                        </div>
                        <div className="card-body pb-2 text-center">
                          {!isQRCreateSuccess && (
                            <h1 className="text-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="35"
                                height="35"
                                fill="currentColor"
                                className="bi bi-x-circle-fill"
                                style={{ color: 'red', margin: 'auto' }}
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                              </svg>
                            </h1>
                          )}

                          {isQRCreateSuccess && (
                            <h1>
                              <i className="fa fa-check-circle" style={{ color: 'green' }}></i>
                            </h1>
                          )}

                          {!isQRCreateSuccess && (
                            <div className="col-lg-12 col-sm-12 col-xs-12 m-2 text-center">
                              <span className="font-danger error_msg ">
                                Something wrong with your Work Order
                              </span>
                              <div>
                                Try again?{' '}
                                <span
                                  onClick={() => {
                                    setSubmited(false);
                                    dispatch(getQRById(uuid));
                                    dispatch(resetQR());
                                  }}
                                  style={{ color: '#F63854', cursor: 'pointer' }}
                                >
                                  Back
                                </span>
                                .
                              </div>
                            </div>
                          )}

                          {isQRCreateSuccess && (
                            <div className="col-lg-12 col-sm-12 col-xs-12 m-2 text-center">
                              <p>
                                {' '}
                                Your ticket number is <b>{service?.ticket_number}</b>{' '}
                              </p>

                              <span className="font-green " style={{ color: 'green' }}>
                                Your Work Order Is Created Successfully
                              </span>
                              <div>
                                Submit another issue?{' '}
                                <span
                                  onClick={() => {
                                    setSubmited(false);
                                    dispatch(getQRById(uuid));
                                    dispatch(resetQR());
                                  }}
                                  style={{ color: '#F63854', cursor: 'pointer' }}
                                >
                                  Back
                                </span>
                                .
                              </div>
                            </div>
                          )}
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default AssetQRCode;
