/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prefer-const */
/* eslint-disable no-nested-ternary */

import React, { useEffect, useState } from 'react';
import Bowser from "bowser";
import { useDispatch, useSelector } from 'react-redux';
// import { Col, Row } from 'react-bootstrap'; //01829297069
// import moment from 'moment';
import { useLocation, Link, useHistory } from 'react-router-dom';
import Card from '../../Ui/Card';
import { getUserProfile } from '../../redux/features/user/userSlice';
import {
  createQRLoc,
  getQRLocById,
  removeFile,
  resetQR,
  setDescription,
  setEmail,
  setFile,
  setName,
  setPhone,
  // setQrCategory,
  // setQrCategory,
  setTitle,
} from '../../redux/features/qrCode/qrCodeSlice';
import icons from '../../utils/icon';
import './locationQrCode.css';
import doneLoge from '../../assets/img/done.jpg';

// import logo from '../../assets/img/FOX-Logo3.png';
// import { BASE_URL } from '../../utils/axios';
// import { getWorkOrderCategoryByAuthentication } from '../../redux/features/workOrderCategory/workOrderCategorySlice';

function LocationQRCode () {
  const [submited, setSubmited] = useState(false);
  const dispatch = useDispatch();
  const { user, isLoading } = useSelector(state => state.user);
  const {
    locationService,
    title,
    name,
    email,
    phone,
    description,
    // category,
    attachment,
    isQRCreateSuccess,
    isQRGetError,
  } = useSelector(state => state.QRService);
  // const workOrderCategory = useSelector(state => state.workOrderCategory?.workOrderCategories);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const searchPramId = query.get('uuid');

  const uuid = searchPramId?.slice(0, searchPramId.length - 1);

  useEffect(() => {
    // dispatch(getWorkOrderCategoryByAuthentication());
    dispatch(getUserProfile());
    dispatch(getQRLocById(uuid));
  }, [dispatch]);

  const history = useHistory();

  useEffect(() => {
    if (!isLoading && isQRGetError) {
      if (!searchPramId && user) {
        history.push('/');
      } else if (!searchPramId && !user) {
        history.push('/login');
      }
    }
  }, [searchPramId, user, isLoading, isQRGetError]);

  const handelTitle = e => {
    dispatch(setTitle(e.target.value));
  };
  const handelName = e => {
    dispatch(setName(e.target.value));
  };
  const handelEmail = e => {
    dispatch(setEmail(e.target.value));
  };
  const handelPhone = e => {
    dispatch(setPhone(e.target.value));
  };
  const handelDescription = e => {
    dispatch(setDescription(e.target.value));
  };
  const handelAttachment = e => {
    dispatch(setFile(e.target.files[0]));
  };

  const formdata = new FormData();
  formdata.append('title', title);
  formdata.append('name', name);
  formdata.append('email', email);
  formdata.append('phone', phone);
  // formdata.append('category', category);
  formdata.append('description', description);
  if (attachment) {
    formdata.append('attachment', attachment);
  }
  formdata.append('uuid', uuid);

  const handelSubmit = async e => {
    e.preventDefault();
    // if (title && name && phone && email && searchPramId) {
    await dispatch(createQRLoc(formdata));
    setSubmited(true);
    // }
  };
  let locationArray = locationService?.location?.split(',').filter(e => e.trim() !== '');


  const [browserName, setBrowserName] = useState('');


  useEffect(()=>{
    const getBrowserName = () => {
      const browser = Bowser.getParser(window.navigator.userAgent);
      setBrowserName( browser.getBrowserName());;
    };
    getBrowserName()
  })


  const pushToBrowserHomePage = () => {
    if (browserName === 'Microsoft Edge') {
       window.location.href = "https://ntp.msn.com/edge/ntp?locale=en-US&title=New%20tab&dsp=1&sp=Bing&startpage=1&PC=U531"
    }
    else{
      window.location.href = "https://www.google.com"
    }

  };



  return (
    <>
      {!submited ? (
        <section className="pt-3 sr-form" >
            {/* image title */}
            <section className="sr-form-title-wrapper">
                <div className="d-flex justify-content-center">
                  <img
                    // src ={logo}
                    src={locationService?.company_logo}
                    alt="company logo"
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <p
                  className='sr-form-title'
                  >
                    Facility Service Request
                  </p>
                </div>
              <div className="d-flex justify-center">
                <p
                className='sr-form-p'
                >
                  We are happy to know about your concerns!
                </p>
              </div>
            </section>
            <form onSubmit={handelSubmit}>
            <section
              style={{
                marginTop: '0',
              }}
            >
                <div>
                  <input
                    style={{
                      backgroundColor: '#FFFFFF',
                      boxShadow: 'none',
                      height: '40px',
                      border: '1px solid #EAEBEC',
                      borderRadius: '8px',
                      padding: '10px 14px 10px 14px',
                      fontFamily: 'Raleway',
                      fontWeight: 500,
                    }}
                    type="text"
                    value={`${locationService?.site} ${
                      locationService.location ? locationArray : ''
                    }`}
                  />
                </div>
                <div className="mt-3">
                  <input
                    required
                    style={{
                      backgroundColor: '#FFFFFF',
                      boxShadow: 'none',
                      height: '40px',
                      border: '1px solid #EAEBEC',
                      borderRadius: '8px',
                      padding: '10px 14px 10px 14px',
                      fontFamily: 'Raleway',
                      fontWeight: 400,
                    }}
                    type="text"
                    placeholder="Feedback or Request Title"
                    onChange={handelTitle}
                  />
                </div>
                <div className="mt-3">
                  <input
                    required
                    style={{
                      backgroundColor: '#FFFFFF',
                      boxShadow: 'none',
                      height: '40px',
                      border: '1px solid #EAEBEC',
                      borderRadius: '8px',
                      padding: '10px 14px 10px 14px',
                      fontFamily: 'Raleway',
                      fontWeight: 400,
                    }}
                    type="text"
                    placeholder="Name"
                    onChange={handelName}
                  />
                </div>
                <div className="mt-3">
                  <input
                  required
                    style={{
                      backgroundColor: '#FFFFFF',
                      boxShadow: 'none',
                      height: '40px',
                      border: '1px solid #EAEBEC',
                      borderRadius: '8px',
                      padding: '10px 14px 10px 14px',
                      fontFamily: 'Raleway',
                      fontWeight: 400,
                    }}
                    onChange={handelEmail}
                    type="email"
                    placeholder="Email"
                  />
                </div>
                <div className="mt-3">
                  <input
                  required
                    style={{
                      backgroundColor: '#FFFFFF',
                      boxShadow: 'none',
                      height: '40px',
                      border: '1px solid #EAEBEC',
                      borderRadius: '8px',
                      padding: '10px 14px 10px 14px',
                      fontFamily: 'Raleway',
                      fontWeight: 400,
                    }}
                    onChange={handelPhone}
                    type="text"
                    placeholder="Phone Number"
                  />
                </div>
                <div className="mt-3">
                  <textarea
                    style={{
                      // backgroundColor: '#FFFFFF',
                      boxShadow: 'none',
                      height: '130px',
                      border: '1px solid #EAEBEC',
                      borderRadius: '8px',
                      padding: '10px 14px 10px 14px',
                      fontFamily: 'Raleway',
                      fontWeight: 400,
                    }}
                    type="text"
                    onChange={handelDescription}
                    placeholder={`Describe your Problem & Location\n \ne.g. The light is blinking at the level 1 lobby `}
                  />
                </div>
                <div className="file-input-container mt-3">
                  <input
                    onChange={handelAttachment}
                    type="file"
                    name="image"
                    id="image"
                    accept="image/png, image/jpeg"
                    className="file-input"
                  />
                </div>
                {attachment?.name && (
                  <div className="file-details-container mt-3">
                    <div className="file-display">
                      <span className="file-icon">📄</span>
                      <span className="file-name">{attachment.name}</span>
                      <span className="file-size">
                        {(attachment.size / (1024 * 1024)).toFixed(2)} MB
                      </span>
                      <button
                        onClick={() => dispatch(removeFile())}
                        type="button"
                        className="remove-button"
                      >
                        ✖
                      </button>
                    </div>
                  </div>
                )}

                <div className="mt-3">
                  <button
                  className='sr-form-submit'
                    type="submit"
                    style={{
                      height: '40px',
                      backgroundColor: '#404040',
                      borderRadius: '8px',
                      padding: '10px 16px 10px 16px',
                      color: '#FFFFFF',
                      fontFamily: 'Raleway',
                      fontWeight: 600,
                      fontSize: '14px',
                    }}

                  >
                    Submit
                  </button>
                </div>
            </section>
            </form>

            <section className="d-flex justify-content-center">
              <div
                style={{
                  fontFamily: 'sans-serif',
                  fontWeight: 500,
                  lineHeight: '15px',
                  color: '#19191966',
                  marginTop: '30px',
                }}
              >
                <p style={{ fontSize: '16px', fontFamily: "sans-serif" }} className="text-center">
                  FaMES By TNBES IFM
                </p>
                <p style={{ fontSize: '14px' }} className="text-center">
                  Powered by FOX{' '}
                </p>
              </div>
            </section>
        </section>
      ) : (
        isQRCreateSuccess ?
        <section
          style={{
            backgroundColor: '#F7F7F7',
            height: '100vh',

          }}
          className=" d-flex justify-content-center align-items-center"
        >
          <div className='d-flex justify-content-center align-items-center' style={{flexDirection:"column"}}>
            <img src={doneLoge} alt="" />
            <p
              style={{
                fontFamily: 'Raleway',
                fontWeight: 600,
                fontSize: '18px',
                color: '#ADADAD',
                textAlign: 'center',
                marginTop: '15px',
              }}
            >
              Thank you <br /> Submitted Successfully
            </p>
            <button
              type="button"
              style={{
                backgroundColor: '#404040',
                borderRadius: '8px',
                padding: '10px 12px 10px 12px',
                height: '40px',
                width: '300px',
                color: '#FFFFFFFF',
                fontFamily: 'Raleway',
              }}
              onClick={pushToBrowserHomePage}
            >
              Done
            </button>
          </div>
        </section> : 
         <section
         style={{
           backgroundColor: '#F7F7F7',
           height: '100vh',
         }}
         className=" d-flex justify-content-center align-items-center"
       >
         <div className='d-flex justify-content-center align-items-center' style={{flexDirection:"column"}}>
         <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="300"
                                height="300"
                                fill="currentColor"
                                className="bi bi-x-circle-fill"
                                style={{ color: 'red', margin: 'auto' }}
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                              </svg>
           <p
             style={{
               fontFamily: 'Raleway',
               fontWeight: 600,
               fontSize: '18px',
               color: '#ADADAD',
               textAlign: 'center',
               marginTop: '15px',
             }}
           >
             Error! <br /> Something wrong with your Work Order <br /> Try again?{' '}
           </p>
           <button
             type="button"
             style={{
               backgroundColor: '#404040',
               borderRadius: '8px',
               padding: '10px 12px 10px 12px',
               height: '40px',
               width: '300px',
               color: '#FFFFFFFF',
               fontFamily: 'Raleway',
             }}
             onClick={() => {
                                     setSubmited(false);
                                     dispatch(getQRLocById(uuid));
                                     dispatch(resetQR());
                                   }}
                                   className='m-small-font'
                >
             Back
           </button>
         </div>
       </section>
      )}
    </>
  );
}

export default LocationQRCode;
// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// // import { Col, Row } from 'react-bootstrap'; //01829297069
// // import moment from 'moment';
// import { useLocation, Link, useHistory } from 'react-router-dom';
// import Card from '../../Ui/Card';
// import { getUserProfile } from '../../redux/features/user/userSlice';
// import {
//   createQRLoc,
//   getQRLocById,
//   resetQR,
//   setDescription,
//   setEmail,
//   setFile,
//   setName,
//   setPhone,
//   // setQrCategory,
//   // setQrCategory,
//   setTitle,
// } from '../../redux/features/qrCode/qrCodeSlice';
// // import logo from '../../assets/img/FOX-Logo3.png';
// // import { BASE_URL } from '../../utils/axios';
// // import { getWorkOrderCategoryByAuthentication } from '../../redux/features/workOrderCategory/workOrderCategorySlice';

// function LocationQRCode() {
//   const [submited, setSubmited] = useState(false);
//   const dispatch = useDispatch();
//   const { user, isLoading } = useSelector(state => state.user);
//   const {
//     locationService,
//     title,
//     name,
//     email,
//     phone,
//     description,
//     // category,
//     attachment,
//     isQRCreateSuccess,
//     isQRGetError,
//   } = useSelector(state => state.QRService);
//   // const workOrderCategory = useSelector(state => state.workOrderCategory?.workOrderCategories);
//   const { search } = useLocation();
//   const query = new URLSearchParams(search);
//   const searchPramId = query.get('uuid');

//   const uuid = searchPramId?.slice(0, searchPramId.length - 1);

//   useEffect(() => {
//     // dispatch(getWorkOrderCategoryByAuthentication());
//     dispatch(getUserProfile());
//     dispatch(getQRLocById(uuid));
//   }, [dispatch]);

//   const history = useHistory();

//   useEffect(() => {
//     if (!isLoading && isQRGetError) {
//       if (!searchPramId && user) {
//         history.push('/');
//       } else if (!searchPramId && !user) {
//         history.push('/login');
//       }
//     }
//   }, [searchPramId, user, isLoading, isQRGetError]);

//   const handelTitle = e => {
//     dispatch(setTitle(e.target.value));
//   };
//   const handelName = e => {
//     dispatch(setName(e.target.value));
//   };
//   const handelEmail = e => {
//     dispatch(setEmail(e.target.value));
//   };
//   const handelPhone = e => {
//     dispatch(setPhone(e.target.value));
//   };
//   const handelDescription = e => {
//     dispatch(setDescription(e.target.value));
//   };
//   const handelAttachment = e => {
//     dispatch(setFile(e.target.files[0]));
//   };

//   const formdata = new FormData();
//   formdata.append('title', title);
//   formdata.append('name', name);
//   formdata.append('email', email);
//   formdata.append('phone', phone);
//   // formdata.append('category', category);
//   formdata.append('description', description);
//   if (attachment) {
//     formdata.append('attachment', attachment);
//   }
//   formdata.append('uuid', uuid);

//   const handelSubmit = async e => {
//     e.preventDefault();
//     // if (title && name && phone && email && searchPramId) {
//       await dispatch(createQRLoc(formdata));
//       setSubmited(true);
//     // }
//   };
//   let locationArray = locationService?.location?.split(',')
//   .filter((e) => e.trim() !== '');
  
  
//   return (
//     <div className='ticket-Request-wrapper'>
//       {!submited && (
//         <>
//           <div className="row ml-0 mr-0">
//             {/* <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12"> */}
//             <div className="col col-lg-12 col-md-4 col-sm-12 col-xs-12 logo" style={{
//                 display: 'flex',
//                 justifyContent: 'center',
//               }}>
//               {locationService?.company_logo && (
//                 <img
//                   src={locationService?.company_logo}
//                   alt="logo"
//                   style={{
//                     width: '150px',
//                     height: 'auto',
//                     margin: '30px 30px 10px 30px',
//                     borderRadius: '20%',
//                   }}
//                 />
//               ) }
//             </div>
//             <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 text-center">
//               {/* <Row className="justify-content-center">
//                 <Col xs={12} className="text-center">
//                   {locationService?.company_name}
//                 </Col>
//               </Row> */}
//               <h3
//                 className='small-font-title'
//                 style={{
//                   width: 'fit-content',
//                   marginLeft: 'auto',
//                   marginRight: 'auto',
//                 }}
//               >
//                 {locationService?.company_name}
//               </h3>
//               <h2 
//                 className='small-font-title'
//                 style={{
//                   fontWeight:'bold',
//                   width: 'fit-content',
//                   marginLeft: 'auto',
//                   marginRight: 'auto',
//                 }}
//               >
//                 Facility Service Request
//               </h2>
//             </div>
//           </div>
//           <div className="row mt-5 ml-0 mr-0">
//             <div className="col col-lg-12 col-sm-12 col-md-12 col-xs-12">
//               <div className="col-lg-12 col-sm-12 col-xs-12 m-2">
//                 <span className="font-danger error_msg float-left" id="serv_err_msg"></span>
//               </div>
//               <Card>
//                 <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12">
//                   <div className="medium-title">
//                     <h4>
//                       <b className='small-font-bold'>Location details</b>
//                     </h4>
//                   </div>
//                   <div className=" pb-1">
//                     <div className="row medium-title">
//                       <div className="col-sm-6 col-md-6 col-lg-6 col-xs-12 row">
//                         <label className="ml-3 small-font-bold mb-0">Location: </label>
//                         <span className="ml-2">{locationService?.site}</span>
//                       </div>
//                       {locationService?.location && (
//                         <div className="col-sm-6 col-md-6 col-lg-6 col-xs-12 row">
//                           <label className="ml-3 small-font-bold mb-0">Sublocation:</label>
//                           <span className="ml-2">
//                             {locationArray.filter(element=>element!==undefined).map((element, index) => {
//                               return (
//                                 <span key={element}>
//                                   {element}
//                                   {index < locationArray.length - 1 ? ', ' : ''} 
//                                 </span>
//                               );
//                             })}
//                           </span>
//                         </div>
//                       )}
//                     </div>
//                   </div>
//                 </div>
//               </Card>
//               {user && locationService?.asset?.length > 0 && (
//                 <Card>
//                   <section className="content">
//                     <div className="row">
//                       <div className="col-lg-12 col-sm-12">
//                         <div className="mt-2">
//                           <div>
//                             <h3 className="card-title mt-2">
//                               <b className='small-font-bold'>Assets</b>
//                             </h3>
//                             {/* <input type="text" id="asset-search" placeholder="Search" className="rgfloat p-1" /> */}
//                           </div>
//                           <div
//                             className="w-100  pb-3 overflow-x-auto overflow-y-auto"
//                             style={{ maxHeight: '400px' }}
//                           >
//                             <table
//                               id="assets-list"
//                               className="table table-striped table-togglable w-100  table-borderless px-2 shadow-sm"
//                             >
//                               <thead>
//                                 <tr>
//                                   <th>Serial/Barcode Number</th>
//                                   <th>Name</th>
//                                   <th>Asset Type</th>
//                                   <th>Location</th>
//                                   <th>Status</th>
//                                 </tr>
//                               </thead>
//                               <tbody>
//                                 {locationService?.asset?.length > 0 &&
//                                   locationService?.asset?.map(data => (
//                                     <tr key={data?.serial_number}>
//                                       <td>
//                                         <Link
//                                           style={{ color: '#F63854' }}
//                                           to={`/assets/${data?.uuid}`}
//                                           title="View Asset"
//                                         >
//                                           {data?.asset_number}
//                                         </Link>
//                                       </td>
//                                       <td>{data?.name}</td>
//                                       <td>{data?.asset_type__name}</td>
//                                       <td>{data?.location__site}</td>
//                                       <td>{data?.status__name}</td>
//                                     </tr>
//                                   ))}
//                               </tbody>
//                             </table>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </section>
//                 </Card>
//               )}
//               <Card>
//                 <form action="action" method="POST" onSubmit={handelSubmit}>
//                   <div>
//                     <div className="mt-2">
//                       <h4>
//                         <b className='small-font-bold'>Create your service request</b>
//                       </h4>
//                     </div>

//                     <div className="pb-0">
//                       <input name="created_date" id="created_date" type="hidden" />
//                       <label className="font-weight-normal m-small-font">
//                         Complaint/Service Request/Suggestion<span className="font-danger">*</span>
//                       </label>
//                       <input
//                         type="text"
//                         name="wo_title"
//                         id="wo_title"
//                         className="form-control mb-2"
//                         
//                         value={title}
//                         onChange={handelTitle}
//                       />
//                       <div className="row">
//                         <div className="col-lg-6 col-sm-12 col-md-6">
//                           <label className="font-weight-normal m-small-font">
//                             Name<span className="font-danger">*</span>
//                           </label>
//                           <input
//                             type="text"
//                             name="name"
//                             id="name"
//                             className="form-control mb-2"
//                             onChange={handelName}
//                             value={name}
//                             
//                           />
//                           <span
//                             className="font-danger float-left col-lg-12 col-md-12 col-sm-12"
//                             id="name_error"
//                           ></span>
//                         </div>
//                         <div className="col-lg-6 col-sm-12 col-md-6">
//                           <label className="font-weight-normal m-small-font">
//                             Email<span className="font-danger">*</span>
//                           </label>
//                           <input
//                             type="email"
//                             name="email"
//                             id="email"
//                             onChange={handelEmail}
//                             className="form-control mb-2"
//                             value={email}
//                             
//                           />
//                         </div>
//                         <div className="col-lg-6 col-sm-12 col-md-6">
//                           <label className="font-weight-normal m-small-font">
//                             Phone<span className="font-danger">*</span>
//                           </label>
//                           <input
//                             type="text"
//                             name="phone"
//                             id="phone"
//                             
//                             value={phone}
//                             onChange={handelPhone}
//                             className="form-control mb-2"
//                           />
//                           <div className="row"></div>
//                         </div>
//                         {/* <div className="col-lg-6 col-sm-12 col-md-6">
//                           <label className="font-weight-normal">Category</label>
//                           <select
//                             className="form-control select-dash custom-select2 mb-2"
//                             name="category"
//                             id="category2"
//                             onClick={e => dispatch(setQrCategory(e.target.value))}
//                           >
//                             <option value="" disabled selected>
//                               -SELECT-
//                             </option>
//                             {locationService?.category &&
//                               locationService?.category.length > 0 &&
//                               locationService?.category.map(cat => (
//                                 <option key={cat} value={cat}>
//                                   {cat}
//                                 </option>
//                               ))}
//                           </select>
//                         </div> */}
//                         <div id="comment" className="col-lg-12 col-sm-12 col-md-12">
//                           <label className="font-weight-normal m-small-font">
//                             Describe your Problem & Location<span className="font-danger">*</span>
//                           </label>
//                           <textarea
//                             onChange={handelDescription}
//                             value={description}
//                             name="comment"
//                             id="comment_desc"
//                             className="form-control mb-2"
//                             
//                           ></textarea>
//                           <span
//                             className="font-danger float-left col-lg-12 col-md-12 col-sm-12"
//                             id="comment_error"
//                           ></span>
//                         </div>
//                       </div>
//                       <label className="font-weight-normal m-small-font">Attachment</label>
//                       <input
//                         type="file"
//                         name="image"
//                         id="image"
//                         className="form-control mb-2 bg-white m-small-font"
//                         style={{ border: 'none' }}
//                         onChange={handelAttachment}
//                         accept="image/*"
//                       />
//                     </div>
//                     <center>
//                       <button type="submit" id="srvc-btn" className="btn primary-btn mb-5 m-small-font">
//                         Submit
//                       </button>
//                     </center>
//                   </div>
//                 </form>
//               </Card>
//             </div>
//           </div>
//         </>
//       )}

//       {submited && (
//         <>
//           {' '}
//           <div className="row ml-0 mr-0">
//             {/* <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12"> */}
//             {/*  <div
//               className="col col-lg-12 col-md-4 col-sm-12 col-xs-12 logo"
//               style={{
//                 display: 'flex',
//                 justifyContent: 'center',
//               }}
//             >
//               {locationService?.company_logo ? (
//                 <img
//                   src={locationService?.company_logo}
//                   alt="logo"
//                   style={{
//                     width: '150px',
//                     height: 'auto',
//                     margin: '30px 30px 10px 30px',
//                     borderRadius: '20%',
//                   }}
//                 />
//               ) : (
//                 <img src={logo} alt="logo" height="130" width="250" />
//               )}
//             </div> */}
//             <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 text-center">
//               <h1
//               className='small-font-title'
//                 style={{
//                   width: 'fit-content',
//                   padding: "10px",
//                   marginLeft: 'auto',
//                   marginRight: 'auto',
//                 }}
//               >
//                 {locationService?.company_name}
//               </h1>
//             </div>
//             {/* </div> */}
//           </div>
//           <div className="row mt-5 ml-0 mr-0">
//             <div className="col-lg-12 col-sm-12 col-xs-12 m-2">
//               <span className="font-danger error_msg float-left" id="serv_err_msg"></span>
//             </div>

//             <div className="col-lg-12 col-sm-12 col-md-12 col-xs-12 pl-3 pr-3">
//               <section className="content">
//                 <div className="row">
//                   <div className="col-lg-12 col-sm-12">
//                     <div>
//                       <Card>
//                         <div className="text-center">
//                           {!isQRCreateSuccess && <h1 className='small-font-bold'>Error!</h1>}
//                           {isQRCreateSuccess && <h1 className='small-font-bold'>THANK YOU!</h1>}
//                         </div>
//                         <div className="pb-2 text-center">
//                           {!isQRCreateSuccess && (
//                             <h1 className="text-center">
//                               <svg
//                                 xmlns="http://www.w3.org/2000/svg"
//                                 width="35"
//                                 height="35"
//                                 fill="currentColor"
//                                 className="bi bi-x-circle-fill"
//                                 style={{ color: 'red', margin: 'auto' }}
//                                 viewBox="0 0 16 16"
//                               >
//                                 <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
//                               </svg>
//                             </h1>
//                           )}

//                           {isQRCreateSuccess && (
//                             <h1>
//                               <i className="fa fa-check-circle" style={{ color: 'green' }}></i>
//                             </h1>
//                           )}

//                           {!isQRCreateSuccess && (
//                             <div>
//                               <span className="font-danger error_msg m-small-font ">
//                                 Something wrong with your Work Order
//                               </span>
//                               <div className='m-small-font'>
//                                 Try again?{' '}
//                                 <span
//                                   onClick={() => {
//                                     setSubmited(false);
//                                     dispatch(getQRLocById(uuid));
//                                     dispatch(resetQR());
//                                   }}
//                                   className='m-small-font'
//                                   style={{ color: '#F63854', cursor: 'pointer' }}
//                                 >
//                                   Back
//                                 </span>
//                                 .
//                               </div>
//                             </div>
//                           )}

//                           {isQRCreateSuccess && (
//                             <div className="col-lg-12 col-sm-12 col-xs-12 m-2 text-center">
//                               <p className='m-small-font'>
//                                 {' '}
//                                 Your ticket number is <b className='small-font-bold'>{locationService?.ticket_number}</b>{' '}
//                               </p>

//                               <p
                              
//                                 className="font-green m-small-font"
//                                 style={{
//                                   color: 'green',
//                                   overflowWrap: 'break-word',
//                                   whiteSpace: 'normal',
//                                 }}
//                               >
//                                 Your Work Order is Created
//                               </p>
//                               <div className='m-small-font'>
//                                 Submit another issue?{' '}
//                                 <span
//                                   onClick={() => {
//                                     setSubmited(false);
//                                     dispatch(getQRLocById(uuid));
//                                     dispatch(resetQR());
//                                   }}
//                                   style={{ color: '#F63854', cursor: 'pointer' }}
//                                 >
//                                   Back
//                                 </span>
//                                 .
//                               </div>
//                             </div>
//                           )}
//                         </div>
//                       </Card>
//                     </div>
//                   </div>
//                 </div>
//               </section>
//             </div>
//           </div>
//         </>
//       )}
//     </div>
//   );
// }

// export default LocationQRCode;
