/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faPlus , faMapMarked } from '@fortawesome/free-solid-svg-icons';
import {
  setLocation,
  openSiteModeul,
  closeSiteModeul,
} from '../../redux/features/location/locationSlice';
import classes from '../../pages/assetsPage/assestsPage.module.css';

export default function LocationHeader ({
  showSearchBar,
  setSearchBar,
  stylesHow,
  handlehow,
  data,
  showAdd,
  locations = [],
  myLocation,
  closeAdd,
  setSearchValue,
  locationSearcValue
}) {
  const dispatch = useDispatch();
  const [title, setTitle] = useState(myLocation || {});
  function handleLocation (id) {
    const location = locations.find(e => e?.id === id);
    setTitle(location);
    dispatch(setLocation(location));
    handlehow();
    closeAdd();
    dispatch(openSiteModeul());
  }
  const getFilteredItems = (query, lo) => {
    if (!query) {
      return lo;
    }
    return lo.filter(i => i.site.toLowerCase().includes(query.toLowerCase()));
  };
  const [query, setQuery] = useState('');
  const filteredItems = getFilteredItems(query, locations);
  const { profile } = useSelector(state => state.company);
  const  {locationsCount}  = useSelector(state => state.location);
  const limitOfLocations = profile?.subscription?.allowed_locations
  const isLocationLimitEnabled = profile?.subscription?.enable_locations_limit
 
  return (
    <div className={classes.assetsTop}>
      <h4 className={classes.assetsTitle}>Locations</h4>
      {
        showSearchBar && <div className={classes.assetActions}>
         {isLocationLimitEnabled && <div className="row info-users">
          <p className="my-auto">
            {locationsCount}/{limitOfLocations}
          </p>
          <FontAwesomeIcon icon={faMapMarked} />
        </div>}
        <input 
        onChange={(e)=>dispatch(setSearchValue(e.target.value))} 
        type="text" placeholder="Search" value={locationSearcValue} />
        {data?.role !== 'Technical Team' && (
          <button
            disabled
            onClick={() => {
              dispatch(setSearchBar(false))
              showAdd();
              // handlehow();
              dispatch(closeSiteModeul());
            }}
            type="button"
            className={classes.assetfaPlus}
          >
            <span>
              <FontAwesomeIcon icon={faPlus} />
            </span>
          </button>
        )}
      </div>
      }
    </div>
  );
}
