/* eslint-disable react/prop-types */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/self-closing-comp */
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { toast } from "react-toastify";
// import Card from 'react-bootstrap/Card';
// import Dropdown from 'react-bootstrap/Dropdown';
import { w3cwebsocket as W3cwebsocket } from 'websocket';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  // faBars, 
  faGear 
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { Nav, NavDropdown } from 'react-bootstrap';
import { Drawer } from '@material-ui/core';
import styles from './layout.scss';
import { tabs } from '../../utils/navData';
import profilePicture from '../../assets/img/profile.png';
import altCompanyPic from '../../assets/img/FOX_Logo2.png.webp';
import { logout } from '../../redux/features/auth/authSlice'; // import your logout action
import { getUserProfile } from '../../redux/features/user/userSlice';
import { getCompanyProfile } from '../../redux/features/compay/companySlice';
import { reset, getNotificationSettings, getNotificationUuid, setNotificationSettings, setNotifications, updateNotificationSettings } from '../../redux/features/notification/notificationSlice';
import {
  setTab as WOTab, closeWorkOrderModalUpdate, openWorkOrderModalUpdate,
  reset as WOReset, resetWorkOrder, getPagination as getWOPagination, getWorkOrderById,
  setUploadMeterAttachment,
  setCurrentReading
} from '../../redux/features/workOrder/workOrderSlice'; import { setTab as PMTab, closeMaintenanceModalUpdate, openMaintenanceModalUpdate, reset as PMReset, resetMaintenance, getPagination as getPMPagination, getMaintenanceById } from '../../redux/features/maintenance/maintenanceSlice';
import EditWOModal from '../../pages/workOrder/Componets/EditWOModal';
import EditPMModal from '../../pages/viewMaintenance/Componets/EditPMModal';
import { changeFavicon } from '../../utils/changeFavicon';
import Loader from '../loader/loader';
import PopUpEditModal from '../popUpModals/editCategory/popUpEditModal';
import { FRONT_URL } from '../../utils/axios';
import { closeMeterModalUpdate, reset as MeterReset, resetMeter } from '../../redux/features/meter/meterSlice';
import EditMeterModal from '../../pages/meter/Componets/EditMeterModal';
import { setBoolValue, setPathValue } from '../../redux/features/location/locationSlice';
// import PopUpReceiveNotifModal from '../popUpModals/notification/popUpReceiveNotifModal';
import NavBarsIcon from './components/icons/NavBarsIcon';
import BellIcon from './components/icons/BellIcon';
// import BellIcon from './components/icons/BellIcon';

export default function Layout({ children }) {
  const location = useLocation();
  const {pathname} = location;
  const [open, setOpen] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [logo, setLogo] = useState(profilePicture);
  const [tabsForPermisions, setTabsForPermisions] = useState([]);
  const [isShowNot, setIsShowNot] = useState(false);
  const [isShowOnlyUnread, setIsShowOnlyUnread] = useState(JSON.parse(localStorage.getItem('showOnlyUnRead')) || false);
  const [isNotSettingsModalUpdate, setIsNotSettingsModalUpdate] = useState(false);
  const [isNotComming, setIsNotComming] = useState(false);
  const myRef = useRef()
  const { user } = useSelector(state => state.user);
  const { profile } = useSelector(state => state.company);

  const { uuid, notifications, notificationSettings
    , isNotificationSettingUpdateError, isNotificationSettingUpdateSuccess
  } = useSelector(state => state.notification);
  const menuRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  // const [ setSelectedStatus] = useState('Available');
  const [showStatusOptions, setShowStatusOptions] = useState(false);

  // const handleStatusChange = (status) => {
  //   setSelectedStatus(status);
  // };
  const handleStatusClick = () => {
    setShowStatusOptions(!showStatusOptions);
  };
  useEffect(() => {
    dispatch(getUserProfile());
    dispatch(getCompanyProfile());
  }, []);


  //  Company Information
  const [info, setInfo] = useState({
    name: '',
    logo: '',
    status: ''
  });

  useEffect(() => {
    setInfo({
      name: profile?.name,
      logo: profile?.logo !== null && profile?.logo !== undefined ? profile?.logo : info.logo,
      status: profile?.status
    });
  }, [profile]);

  changeFavicon();

  useEffect(() => {
    if (user?.profile.image?.image) {
      setLogo(user?.profile.image?.image);
    } else setLogo(user?.profile.image);
  }, [user]);

  useEffect(() => {
    dispatch(getNotificationUuid())
    const notifiData = JSON.parse(localStorage.getItem("notification")) || []
    // console.log(notifiData)
    if (isShowOnlyUnread) {
      const notShowOnlyUnread = notifiData?.filter(item => {
        return item.read === false
      })
      dispatch(setNotifications(notShowOnlyUnread))
    } else {
      dispatch(setNotifications(notifiData))
    }
    dispatch(getNotificationSettings())
  }, []);

  useEffect(() => {
    localStorage.setItem('showOnlyUnRead', JSON.stringify(isShowOnlyUnread));
  }, [isShowOnlyUnread]);

  // // PopUp New Notification
  // const [showPopUpNotif, setShowPopUpNotif] = useState(false);
  // const [popUpNotifContent, setPopUpNotifContent] = useState(null);
  // const [, setNotifPopUpQueue] = useState([]);

  // function triggerNotifPopUp(notif) {
  //   setPopUpNotifContent(notif)
  //   setShowPopUpNotif(true)
  // }

  // function handleClosePopUpNotif() {
  //   setShowPopUpNotif(false)
  //   setNotifPopUpQueue(queue => {
  //     const [, ...rest] = queue

  //     if (rest.length > 0) {
  //       triggerNotifPopUp(rest[0])
  //     }
  //     return rest;
  //   })
  // }
  // // End PopUp New Notification

  let socket;
  useEffect(() => {
    if (uuid) {
       socket = new W3cwebsocket(`wss://asset.ifmtnbes.com.my/ws/notification/?uuid=${uuid}`);

      socket.onopen = () => {
        console.log('WebSocket connection opened.');
      };

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        // console.log(data)
        let notifi = JSON.parse(localStorage.getItem("notification")) || [];
        const currentDate = moment()
        const futureDate = currentDate.add(3, 'days').format("YYYY-MM-DDTHH:mm");
        if (notifi.length > 0) {
          notifi = notifi.filter(item => (moment().diff(moment(item?.date).format("YYYY-MM-DDTHH:mm:ss"), "seconds")) < 0);
        }
        const modifiedNotifications = data?.payload?.map(item => {
          return { ...item, date: futureDate }
        })

        notifi.unshift(...modifiedNotifications)
        localStorage.setItem("notification", JSON.stringify(notifi));
        dispatch(setNotifications(notifi))
        setIsNotComming(true)

        // add notif popup queue
        // setNotifPopUpQueue(prevQueue => {
        //   const updatedQueue = [...prevQueue, ...modifiedNotifications]
        //   const filteredOnlyCreatedWorkOrder = updatedQueue.filter(item => item.message.includes("created"));
          
        //   // If the modal is not open, show the first notification immediately
        //   if (!showPopUpNotif && filteredOnlyCreatedWorkOrder.length > 0) {
        //     triggerNotifPopUp(filteredOnlyCreatedWorkOrder[0])
        //   }
        //   return filteredOnlyCreatedWorkOrder
        // })

        socket.send(JSON.stringify({ "type": "delete.notifications", "data": {} })); // Send the message
      };
    }

    // Clean up the WebSocket connection when the component unmounts
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [uuid]);

  useEffect(() => {
    if (isNotComming && isShowOnlyUnread) {
      const notifi = JSON.parse(localStorage.getItem("notification")) || [];
      const notShowOnlyUnread = notifi?.filter(item => {
        return item.read === false
      })
      dispatch(setNotifications(notShowOnlyUnread))
      setIsNotComming(false)
    }
  }, [isNotComming, isShowOnlyUnread])


  useEffect(() => {
    const handler = e => {
      if (menuRef) {
        if (!menuRef?.current?.contains(e.target) && window.innerWidth < 992) {
          setOpen(false);
        }
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, []);

  useEffect(() => {
    if (user) {
      if (user?.role === 'CliAdm') {
        setTabsForPermisions(tabs);
      } else if (user?.role === 'Requester') {
        setTabsForPermisions(tabs?.filter(tab => tab.id === 2 || tab.id === 4));
      } else if (user?.role === 'Technical Team' || user?.role === 'Manager') {
        setTabsForPermisions(tabs?.filter(tab => tab.id !== 11 && tab.id !== 12));
      }
    }
  }, [user]);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    localStorage.removeItem('userToken');
    dispatch(logout()).then(() => {
      // history.push('/login');
      window.location.href = FRONT_URL;
      // window.location.reload();
    });
  }

  const token = localStorage.getItem('userToken');
  if (!token) {
    history.push('/login');
  }


  const handleTabClick = index => {
    setSelectedIndex(index);
    setIsShowNot(false)
  };

  const {
    workOrderModalUpdate,
    isWorkOrderDeleteSuccess,
    isWorkOrderUpdateSuccess,
    isWorkOrderDeleteError,
    isWorkOrderUpdateError
  } = useSelector(state => state.workOrder)
  const {
    maintenanceModalUpdate,
    isMaintenanceDeleteSuccess,
    isMaintenanceUpdateSuccess,
    isMaintenanceDeleteError,
    isMaintenanceUpdateError
  } = useSelector(state => state.maintenance)
  const {
    meterModalUpdate,
    isMeterDeleteSuccess,
    isMeterUpdateSuccess,
    isMeterDeleteError,
    isMeterUpdateError
  } = useSelector(state => state.meter)

  const { boolValue } = useSelector(state => state.location);

  function displayToast() {
    if (isWorkOrderUpdateError) {
      toast.error(' Something went wrong Updating WorkOrder', {
        toastId: 'UpdatingWorkOrderError',
      });
      dispatch(WOReset())
    } else if (isWorkOrderDeleteError) {
      toast.error(' Something went wrong Deleting WorkOrder', {
        toastId: 'DeletingWorkOrderError',
      });
      dispatch(WOReset())
    } else if (isWorkOrderDeleteSuccess) {
      toast.success(' WorkOrder Deleted successfully', {
        toastId: 'DeletedWorkOrderError',
      });
      dispatch(WOReset())
      dispatch(resetWorkOrder())
      dispatch(closeWorkOrderModalUpdate())
    }
    else if (isWorkOrderUpdateSuccess) {
      toast.success(' WorkOrder Updated successfully', {
        toastId: 'UpdatedWorkOrderError',
      });
      dispatch(setUploadMeterAttachment(""))
      dispatch(setCurrentReading(""))
      dispatch(WOReset())
    }
    else if (isMaintenanceUpdateError) {
      toast.error(' Something went wrong Updating Maintenance', {
        toastId: 'UpdatingMaintenanceError',
      });
      dispatch(PMReset())
    } else if (isMaintenanceDeleteError) {
      toast.error(' Something went wrong Deleting Maintenance', {
        toastId: 'DeletingMaintenanceError',
      });
      dispatch(PMReset())
    } else if (isMaintenanceDeleteSuccess) {
      toast.success(' Maintenance Deleted successfully', {
        toastId: 'DeletedMaintenanceError',
      });
      dispatch(PMReset())
      dispatch(resetMaintenance())
      dispatch(closeMaintenanceModalUpdate())
    }
    else if (isMaintenanceUpdateSuccess) {
      toast.success(' Maintenance Updated successfully', {
        toastId: 'UpdatedMaintenanceError',
      });
      dispatch(PMReset())
    } else if (isMeterUpdateError) {
      toast.error('Something went wrong Updating Meter', {
        toastId: 'UpdatingMeterError',
      });
      dispatch(MeterReset())
    } else if (isMeterDeleteError) {
      toast.error('Something went wrong Deleting Meter', {
        toastId: 'DeletingMeterError',
      });
      dispatch(MeterReset())
    } else if (isMeterDeleteSuccess) {
      toast.success('Meter Deleted successfully', {
        toastId: 'DeletedMeterError',
      });
      dispatch(MeterReset())
      dispatch(resetMeter())
      dispatch(closeMeterModalUpdate())
    }
    else if (isMeterUpdateSuccess) {
      toast.success('Meter Updated successfully', {
        toastId: 'UpdatedMeterError',
      });
      dispatch(MeterReset())
    }
    else if (isNotificationSettingUpdateSuccess) {
      toast.success('Notification Settings Updated successfully', {
        toastId: 'UpdatedNotificationSettingError',
      });
      dispatch(reset())
    }
    else if (isNotificationSettingUpdateError) {
      toast.error('Something went wrong Updating Notification Settings', {
        toastId: 'UpdatingNotificationSettingsError',
      });
      dispatch(reset())
    }
  }

  const modfiyMarked = (uuidd, type) => {
    const modNotiArray = []
    const notifi = JSON.parse(localStorage.getItem("notification")) || [];
    notifi.forEach(item => {
      if (item?.uuid === uuidd) {
        if (type === "toRead") {
          modNotiArray.push({ ...item, read: true })
        }
        if (type === "toMark") {
          modNotiArray.push({ ...item, read: !item.read })
        }
      } else {
        modNotiArray.push(item)
      }
    })
    if (isShowOnlyUnread) {
      const data = [...modNotiArray]
      const modNotiWithOnlyUnread = data.filter(item => item.read === false)
      dispatch(setNotifications(modNotiWithOnlyUnread))
    } else {
      dispatch(setNotifications(modNotiArray))
    }

    localStorage.setItem("notification", JSON.stringify(modNotiArray))

  }

  const handelOpenNotification = async (ticketId, type, uuidd, title) => {
    setIsShowNot(false)
    modfiyMarked(uuidd, "toRead")
    if (type === "WORKORDER") {
      await dispatch(getWorkOrderById(ticketId))
      await dispatch(getWOPagination({ rowPerPage: 10, currentPage: 1, searchValue: title }))
      dispatch(openWorkOrderModalUpdate())
      dispatch(WOTab("service-tab"))
    } else {
      await dispatch(getMaintenanceById(ticketId))
      await dispatch(getPMPagination({ rowPerPage: 10, currentPage: 1, searchValue: title }))
      dispatch(openMaintenanceModalUpdate())
      dispatch(PMTab("service-tab"))
    }
  }
  const handelMarkNotification = (uuidd) => {
    modfiyMarked(uuidd, "toMark")
  }
  const handelMarkAll = () => {
    const notMarkAll = notifications?.map(item => {
      return { ...item, read: true }
    })
    dispatch(setNotifications(notMarkAll))
    localStorage.setItem("notification", JSON.stringify(notMarkAll))
  }
  const handelShowOnlyUnread = () => {
    myRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    const notShowOnlyUnread = notifications?.filter(item => {
      return item.read === false
    })
    dispatch(setNotifications(notShowOnlyUnread))
    setIsShowOnlyUnread(true)
  }
  const handelShowAll = () => {
    myRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    const notifi = JSON.parse(localStorage.getItem("notification")) || [];
    dispatch(setNotifications(notifi))
    localStorage.setItem("notification", JSON.stringify(notifi))
    setIsShowOnlyUnread(false)
  }

  const handelSettingsChange = (e) => {
    dispatch(setNotificationSettings({ ...notificationSettings, [e.target.name]: e.target.checked }))
  }

  const handleEditSave = () => {
    setIsNotSettingsModalUpdate(false)
    dispatch(updateNotificationSettings(notificationSettings));
  }

  // const []

  return user ? <body
    className={
      open
        ? 'sidebar-mini layout-fixed sidebar-open'
        : 'sidebar-mini layout-fixed sidebar-closed sidebar-collapse'
    }
    style={{ height: 'auto' }}
  >
    {displayToast()}

      {/* PopUp Receive New Notification */}
      {/* {popUpNotifContent && (
        <PopUpReceiveNotifModal
          showModal={showPopUpNotif}
          handleClose={() => handleClosePopUpNotif()}
          // handleConfirm={handleDelete}
          // modalTitle={`Delete ${rowId?.title}`}
          modalBodyText={popUpNotifContent?.message}
        />
      )} */}

    {/* Start notification Settings */}
    {isNotSettingsModalUpdate && (
      <PopUpEditModal
        handleClose={() => {
          setIsNotSettingsModalUpdate(false)
          dispatch(getNotificationSettings())
        }}
        showModal={isNotSettingsModalUpdate}
        handleSave={handleEditSave}
        modalTitle="Notification Settings"
        primaryButtonText="Save Changes"
      >


        <table className="table table-borderless">
          <thead>
            <tr>
              <td>{" "}</td>
              <td >Email</td>
              <td >Notification</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Completed Ticket</td>
              <td><span >
                <input type="checkbox" checked={notificationSettings?.email_completed_ticket}
                  onChange={handelSettingsChange}
                  name='email_completed_ticket' className='businessHours-status' />
              </span></td>
              <td> <span>
                <input type="checkbox" checked={notificationSettings?.web_completed_ticket}
                  onChange={handelSettingsChange}
                  name='web_completed_ticket' className='businessHours-status' />
              </span></td>
            </tr>
            <tr>
              <td>Ticket Updates</td>
              <td> <span>
                <input type="checkbox" checked={notificationSettings?.email_ticket_updates}
                  onChange={handelSettingsChange}
                  name='email_ticket_updates' className='businessHours-status' />
              </span></td>
              <td> <span >
                <input type="checkbox" checked={notificationSettings?.web_ticket_updates}
                  onChange={handelSettingsChange}
                  name='web_ticket_updates' className='businessHours-status' />
              </span></td>
            </tr>
            <tr>
              <td>New Work Order</td>
              <td> <span>
                <input type="checkbox" checked={notificationSettings?.email_new_wo}
                  onChange={handelSettingsChange}
                  name='email_new_wo' className='businessHours-status' />
              </span></td>
              <td> <span >
                <input type="checkbox" checked={notificationSettings?.web_new_wo}
                  onChange={handelSettingsChange}
                  name='web_new_wo' className='businessHours-status' />
              </span></td>
            </tr>
            <tr>
              <td>New Preventive Maintenance</td>
              <td> <span>
                <input type="checkbox" checked={notificationSettings?.email_new_pm}
                  onChange={handelSettingsChange}
                  name='email_new_pm' className='businessHours-status' />
              </span></td>
              <td> <span >
                <input type="checkbox" checked={notificationSettings?.web_new_pm}
                  onChange={handelSettingsChange}
                  name='web_new_pm' className='businessHours-status' />
              </span></td>
            </tr>
            <tr>
              <td>Assigned Work Order</td>
              <td> <span>
                <input type="checkbox" checked={notificationSettings?.email_assigned_wo}
                  onChange={handelSettingsChange}
                  name='email_assigned_wo' className='businessHours-status' />
              </span></td>
              <td> <span >
                <input type="checkbox" checked={notificationSettings?.web_assigned_wo}
                  onChange={handelSettingsChange}
                  name='web_assigned_wo' className='businessHours-status' />
              </span></td>
            </tr>
            <tr>
              <td>Assigned Preventive Maintenance</td>
              <td> <span>
                <input type="checkbox" checked={notificationSettings?.email_assigned_pm}
                  onChange={handelSettingsChange}
                  name='email_assigned_pm' className='businessHours-status' />
              </span></td>
              <td> <span >
                <input type="checkbox" checked={notificationSettings?.web_assigned_pm}
                  onChange={handelSettingsChange}
                  name='web_assigned_pm' className='businessHours-status' />
              </span></td>
            </tr>
            <tr>
              <td>Low inventory</td>
              <td> <span>
                <input type="checkbox" checked={notificationSettings?.email_low_inventory}
                  onChange={handelSettingsChange}
                  name='email_low_inventory' className='businessHours-status' />
              </span></td>
              <td> <span >
                <input type="checkbox" checked={notificationSettings?.web_low_inventory}
                  onChange={handelSettingsChange}
                  name='web_low_inventory' className='businessHours-status' />
              </span></td>
            </tr>
          </tbody>
        </table>
      </PopUpEditModal>
    )}
    {/* End notification Settings */}
    <div className="wrapper background-grey-white">
      {isShowNot && <div onClick={() => setIsShowNot(false)} className='not-overlay' />}
      <nav className="main-header navbar navbar-expand navbar-trans sticky-top nav-styles">
        <ul className="navbar-nav">
          <li className="nav-item">
            <button 
              type="button" 
              className={styles.menuBtn} 
              onClick={toggleOpen} 
              ref={menuRef}
            >
              {/* <FontAwesomeIcon className="nav-icon" icon={faBars} /> */}
              <NavBarsIcon/>
            </button>
          </li>
        </ul>

        {/* Start notification */}
        <ul className="navbar-nav align-items-center ml-auto">
          <div className="notification" style={{ marginRight: "20px", border: "none", outline: "none" }}>
            <button onClick={() => setIsShowNot(!isShowNot)} type="button" 
              className="icon-button" 
              style={{ 
                border: "none", 
                outline: "none" 
              }} 
            >
              {/* <i className="nav-icon far fa-bell font-grey main-color" /> */}
              <BellIcon />
              <span className="icon-button__badge">
                <p style={{ paddingTop: "15px", fontSize: "10px" }}>{notifications.filter(item => item.read === false)?.length} </p>
              </span>
            </button>
            <Drawer
              anchor="right"
              open={isShowNot}
              onClose={() => setIsShowNot(!isShowNot)}
              PaperProps={{
                style: {
                  width: '100%',
                  maxWidth: '400px',
                  height: '100%',
                },
              }}
            >
              {/* <ul className={isShowNot ? "notification-menu notification-menu-active" : "notification-menu"}> */}
              <div className='notification'>
                <ul className="notification-menu notification-menu-active">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                      marginLeft: '5px',
                      marginBottom: '10px'
                    }}
                  >
                      <li
                        onClick={() => {
                          setIsNotSettingsModalUpdate(true)
                          setIsShowNot(false)
                        }}
                        className='notification-settings'>
                        <span>
                          <FontAwesomeIcon icon={faGear} /> Notification Settings
                        </span>
                      </li>
                    <button
                      onClick={() => setIsShowNot(!isShowNot)}
                      type="button"
                      className="btn secondary-btn ml-auto"
                      data-dismiss="modal"
                    >
                      <div className="fas fa-times"></div>
                    </button>
                  </div>
                  {/* <li>
                    <h3>Notification</h3> 
                  </li> */}
                  <li className='not-actions'>
                    <button onClick={handelMarkAll} className='main-color' type='button'
                      disabled={notifications?.filter(item => {
                        return item.read === false
                      })?.length === 0}
                    > Mark all as read </button>
                    {!isShowOnlyUnread ? <button onClick={handelShowOnlyUnread} className='main-color' type='button'> Show only Unread</button> :
                      <button onClick={handelShowAll} className='main-color' type='button'> Show All</button>}
                  </li>
                  <li ref={myRef} className='notification-items'>
                    {notifications?.length === 0 ? <li>
                      <p><center>There is no notification</center></p>
                    </li> :
                      notifications?.map(noti => {
                        return <li>
                          <div className={noti?.read ? "marked-message" : "message"} >
                            <p onClick={() => handelOpenNotification(noti?.object_data?.id, noti?.object_type, noti?.uuid, noti?.object_data?.title)} >{noti?.message} </p>
                            <div className='date-and-read'>
                              <span>
                                {moment(noti?.created_at).fromNow(true)} ago
                              </span>
                              <div>
                                <button onClick={() => handelMarkNotification(noti?.uuid)} className='main-color' type='button'> {noti?.read ? " Mark Unread" : "Mark read"}</button>
                              </div>
                            </div>
                          </div>
                        </li>
                      })}
                  </li>
                </ul>
              </div>
            </Drawer>
            {/* End notification */}

          </div>
          <Nav>
            <Link to="/profile/">
              <img
                id="dropdown-item-button"
                className="prof_img img-circle mr-0"
                alt="profilePic"
                style={{ marginTop: 4, maxHeight: '30px', maxWidth: '30px', minWidth: '30px', minHeight: '30px' }}
                src={logo}
                title="Change profile picture"
              />
            </Link>
            <NavDropdown
              style={{ color: '#c61f57 !important' }}
              id="nav-dropdown-dark-example"
              title={<span className="text-muted">{user && user.first_name}</span> || "user_name"}

            >

              <NavLink to="/profile/" style={{ marginLeft: '17px', textDecoration: 'none', color: 'inherit' }}>
                User Profile
              </NavLink>

              <NavDropdown.Item href="#action/3.2" onClick={handleStatusClick}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  User Status <div style={{ width: '10px', height: '10px', marginLeft: '15px', marginRight: '5px', marginTop: '4px', borderRadius: '50%', backgroundColor: user.status === true ? 'green' : 'red' }}></div>{user.status === true ? "Available" : "UnAvailable"}
                </div>
              </NavDropdown.Item>
              {showStatusOptions && (
                <>
                  {/* <NavDropdown.Item onSelect={() => handleStatusChange('Available')}><div style={{ display: 'flex', alignItems: 'center' }}><div style={{ width: '10px', height: '10px', marginLeft: '5px', marginRight: '5px', borderRadius: '50%', backgroundColor: 'green' }}></div>Available</div></NavDropdown.Item> */}
                  {/* <NavDropdown.Item onSelect={() => handleStatusChange('Unavailable')}><div style={{ display: 'flex', alignItems: 'center' }}><div style={{ width: '10px', height: '10px', marginLeft: '5px', marginRight: '5px', borderRadius: '50%', backgroundColor: 'red' }}></div>Unavailable</div></NavDropdown.Item> */}
                </>
              )}
            </NavDropdown>
          </Nav>
        </ul>
        <ul className="navbar-nav">
          <li className="nav-item">
            <button className="success gcolor" type="button" onClick={handleLogout}>
              <i className="nav-icon" /> 
              <span style={{width: '1px', height: '27px', background: 'black'}}/>
              <span>
                Sign out
              </span>
            </button>
          </li>
        </ul>
      </nav>
      <div>
        {/* <aside className="main-sidebar sidebar-light-danger elevation-0"> */}
        <aside className="main-sidebar sidebar-light-danger">
          <Link to="/company-profile" className="brand-link brand-link-styles">
            <img
              src={info.logo ? info.logo : altCompanyPic}
              alt="company logo"
              style={{ width: "30px" }}
              className="brand-image img-company"
              id="result_company_base"
            />
            <div>
              <span style={info?.name?.length <= 20 ? { fontSize: "15px" } : { fontSize: "13px" }} className="brand-text font-weight-strong font-grey">
                {info?.name || "FOX Asset"}
              </span>
            </div>
          </Link>
          <div className="sidebar h-100">
            <nav className="h-100">
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
                data-auto-collapse-size="375"
                style={{paddingLeft: "8px", paddingRight: "8px"}}
              >
                {tabsForPermisions?.map(({ id, style, text, icon, path, iconActive }) => {
                  let className = 'nav-link';
                  if (selectedIndex === -1) {
                    className += '';
                  } else if (selectedIndex === id) {
                    className += ' active';
                  }

                  let modifiedText = text; // Create a new variable to hold the modified value

                  if (text === 'Dashboard') {
                    modifiedText = 'Planner'; // Modify the value for 'Dashboard' tab
                  }
                  if (text === 'Analytic Report') {
                    modifiedText = 'Dashboard';
                  }
                  if (text === 'Edit Categories') {
                    modifiedText = 'Customization';
                  }

                  return (
                    <li key={id} className={`nav-item ${style}`}>
                      <NavLink
                        className={className}
                        id={id}
                        to={path}
                        exact
                        onClick={() => {
                          handleTabClick(id)
                          if (path === "/locations") {
                            dispatch(setPathValue("locations"))
                            dispatch(setBoolValue(!boolValue))
                          }
                        }}
                        style={{width: '100%'}}
                      >
                        <div style={{display: "flex", gap: "8px", alignItems: "center"}}>
                          {/* <FontAwesomeIcon className="nav-icon" icon={icon} /> */}
                          <div>
                            {pathname === path ? iconActive : icon}
                          </div>
                          {/* {icon} */}
                          <p className="font-grey sb-item">{modifiedText}</p>
                        </div>
                      </NavLink>
                    </li>
                  );
                })}

                <li className="nav-item text-center mb-5" style={{display: 'flex', justifyContent: 'center'}}>
                  <a
                    href="https://app.startinfinity.com/form/96c5bb22-1c81-4d31-b2cf-469c49fc311b"
                    target="_blank"
                    className="nav-link report-styles "
                    id="trouble"
                    title="Report a bug"
                    rel="noreferrer"
                  >
                    <span className=" gcolor">Having troubles? </span>
                    <span className="a-color">Report here</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </aside>
      </div>
      <div className="content-wrapper">{children}</div>
      {workOrderModalUpdate && <EditWOModal
        openModalUpdate={workOrderModalUpdate}
        closeModalUpdate={() => dispatch(closeWorkOrderModalUpdate())}
      />}
      {maintenanceModalUpdate && <EditPMModal
        openModalUpdate={maintenanceModalUpdate}
        closeModalUpdate={() => dispatch(closeMaintenanceModalUpdate())}
      />
      }
      {meterModalUpdate && (
        <EditMeterModal
          openModalUpdate={meterModalUpdate}
          closeModalUpdate={() => dispatch(closeMeterModalUpdate())}
        />
      )}
    </div>
  </body> : <Loader />
}
